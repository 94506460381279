const Login = () => import("@/views/modulos/auth/login.vue");
const Inscricao = () => import("@/views/modulos/auth/inscricao.vue");
const Matricula = () => import("@/views/modulos/auth/matricula.vue");
const EnsinoAluno = () => import("@/views/modulos/auth/ensino-aluno.vue");
const Eventos = () => import("@/views/modulos/auth/eventos.vue");
const EventosInscr = () => import("@/views/modulos/auth/eventos-inscr.vue");
const EventosParticipante = () => import("@/views/modulos/auth/eventos-participante.vue");
const EventosAdmin = () => import("@/views/modulos/auth/eventos-admin.vue");
const SelBanco = () => import("@/views/modulos/auth/selbancodados.vue");
const Dashboard = () => import("@/views/dashboard.vue");
const cadeventosinscconfig = () => import("@/views/modulos/eventos/cadeventosinscconfig.vue")
const Diploma = () => import("@/views/modulos/auth/diploma.vue");
const DocsConsulta = () => import("@/views/modulos/auth/docs-consulta.vue");

const inscrevento = () => import("@/views/modulos/auth/eventosacad-inscricao-online.vue");
const InscrEventoPortal = () => import("@/views/modulos/auth/eventos-portal-inscrito.vue");
const formcrm = () => import("@/views/modulos/auth/crm-formulario-online")
const pix = () => import("@/views/modulos/auth/pix.vue");

export default [
  { path: "/pix", component: pix },
  { path: "/login", component: Login },
  { path: "/selbanco", component: SelBanco },
  { path: "/dashboard", component: Dashboard },
  { path: "/inscricao", component: Inscricao },
  { path: "/matricula", component: Matricula },
  { path: "/ensino-aluno", component: EnsinoAluno },
  { path: "/eventos", component: Eventos },
  { path: "/eventos-participante", component: EventosParticipante },
  { path: "/eventos-admin", component: EventosAdmin },
  { path: "/cadeventosinscconfig", component: cadeventosinscconfig },
  { path: "/diploma", component: Diploma },
  { path: "/docs-consulta", component: DocsConsulta },
  { path: "/eventos-inscr", component: EventosInscr },
  { path: "/inscrevento", component: inscrevento },
  { path: "/portalinscrevento", component: InscrEventoPortal },
  { path: "/formcrm", component: formcrm },
];
