import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    params: {
      baseApiUrl: null,
      relatorio: null,
      relatoriopdf: null,
      relatorioxls: null,
      uploadlarge: null,
      upload3000: null,
      upload: null,
      viewdocs: null,
      socket: null,
      docs: null
    },
    temas: 
      {
          background: "#303F9F",
          backgroundWindow: "#3F51B5",
          backgroundCorFonte: "#fff"
      },
    usr_foto_perfil: "",
    empresa: "",
    contexto: {
      usuariosConectados: [],
      ws_client: null,
      ws_sessionid: null,
      contrato: null,
      aluno: null,
      BDLista: [
      ],
      DB: [{}],
      FILIAL: [{
        e_nomefantasia: ""
      }],
      titulo: "",
      relatorio: "",
      relatorios:"",
      menu: null,
      menuservicos: "",
      favoritos: "",
      perfilgeral: "",
      api: {
        baseApiUrl: "",
        baseApiUrlUpload: "",
        baseApiRel: "",
        baseApiDocs: "",
        baseApiRelPDF: "",
        baseApiRelXLS: ""        
      },
      usuario: {
        p_id: "",
        func_nome: "",
        usr_id: "",
        usr_nome: "",
        usr_email: "",
        usr_login: "",
        usr_foto_perfil: "",
        //perfil: {},
        aluno: {
          a_id: "",
          a_nome: "",
          s_id: "",
          s_descricao: "",
          m_id: ""
        },
        professor: {
          p_id: "",
          usr_id: "",
          proc_id: "",
          proc_descricao: ""
        },
        perfil: {
          gu_id: "",
          gu_descricao: "",
          perfil: {
            perfil: "",
            p_id: "",
          },
        },
      },
    },
  },
  mutations: {
    setws_sessionid(state, paylod) {
      state.contexto.ws_sessionid = paylod;
    },
    setFilial(state, paylod) {
      state.contexto.FILIAL = paylod;
    },
    setUsuariosConectados(state, paylod) {
      state.contexto.usuariosConectados = paylod;
    },
    setws_client(state, paylod) {
      state.contexto.ws_client = paylod;
    },
    setAPI(state: any, paylod) {
      state.baseApiUrl = paylod;
    },
    setAPIREL(state: any, paylod) {
      state.baseApiRel = paylod;
    },
    setAPIUPLOAD(state: any, paylod) {
      state.baseApiUrlUpload = paylod;
    },
    setAPIDOCS(state: any, paylod) {
      state.baseApiDocs = paylod;
    },
    setAPIRELPDF(state: any, paylod) {
      state.baseApiRelPDF = paylod;
    },
    setAPIRELXLS(state: any, paylod) {
      state.baseApiRelXLS = paylod;
    },
    setRelatorios(state, paylod) {
      state.contexto.relatorios = paylod;
    },
    setPerfilGeral(state: any, paylod) {
      state.contexto.perfilgeral = paylod;
    },
    setUsuarioProfessor(state: any, paylod) {
      console.log(paylod)
      state.contexto.usuario.professor = paylod;
    },
    setPerfil(state: any, paylod) {
      state.contexto.usuario.perfil.perfil = paylod;
      //console.log(paylod)
    },
    setFavoritos(state: any, paylod) {
      state.contexto.favoritos = paylod;
    },
    setContrato(state: any, paylod) {
      state.contexto.contrato = paylod;
    },
    setAluno(state: any, paylod) {
      state.contexto.aluno = paylod;
    },
    setMenu(state: any, paylod) {
      state.contexto.menu = paylod;
    },
    setBD(state: any, paylod) {
      state.contexto.BD = paylod;
    },
    setBDLista(state: any, paylod) {
      state.contexto.BDLista = paylod;
    },
    setTitulo(state: any, paylod) {
      state.contexto.titulo = paylod;
    },
    setRelatorio(state: any, paylod) {
      state.contexto.relatorio = paylod;
    },
    setUsuario(state: any, paylod) {
      state.contexto.usuario = paylod;
    },
    setFotoPerfil(state: any, paylod) {
      state.usr_foto_perfil = paylod;
      state.contexto.usuario.usr_foto_perfil = paylod;
    }
  },
  actions: {},
  modules: {},
});
