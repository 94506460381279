<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"><span style="font-size: 12px">Aguarde</span></v-progress-circular>
    </v-overlay>
    <v-row dense style="margin-top: -15px">
      <v-col>
        <v-select outlined dense hide-details label="Disciplinas" v-model="ds_id" :items="disciplinas" item-text="d_descricao" item-value="ds_id"></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-textarea outlined dense hide-details label="Complemento" v-model="just" rows="2"></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <v-file-input v-model="arquivos" outlined dense label="Arquivos" multiple prepend-icon="mdi-paperclip">
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <Button label="Confirmar" @click="confirmar"></Button>
        <Button label="Cancelar" style="margin-left: 5px" @click="cancelar"></Button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import axios from "axios";

import Config, { formatData, formatMoeda, hoje } from "@/config.ts";

export default {
  name: "solicitacoes-dispensa",
  props: ["aluno", "m_id", "serv_id"],
  data() {
    return {
      disciplinas: "",
      ds_id: "",
      just: "",
      imagem: [],
      arquivos: [],
      file: "",
      sol_id: "",
      overlay: false,
    };
  },
  watch: {
    ds_id: function(novo, antigo) {
      this.just =
        "Aluno solicita aproveitamento dos estudos da disciplina " +
        this.disciplinas.filter((d) => d.ds_id === this.ds_id)[0].d_descricao +
        " conforme documentação anexa";
    },
  },

  created() {
    http
      .send("", {
        proc: "[fx jaguar alunos]",
        1: "listadiscipllinasdispensa",
        2: this.aluno.cga,
        3: this.aluno.s_id,
        4: this.aluno.g_id,
      })
      .then((res) => {
        this.disciplinas = res.dados.data;
        this.ds_id = res.dados.data[0].ds_id;
      });
  },
  methods: {
    listaArquivos() {},
    confirmar() {
      this.overlay = true;
      http
        .send("", {
          proc: "[FX jaguar solicitacoes]",
          1: "dispensa",
          2: this.serv_id,
          3: this.$store.state.contexto.BD[0].usr_id,
          4: this.ds_id,
          5: "",
          6: "",
          7: this.just,
          8: this.m_id,
          9: this.aluno.cga,
        })
        .then((res) => {
          this.sol_id = res.dados.data[0].sol_id;
          for (let index = 0; index < this.arquivos.length; index++) {
            this.upload(this.arquivos[index]);
          }
          this.overlay = false;
          this.$emit("confirmar");
        });
    },

    async upload(file) {
      await http.send("", { proc: "[fx jaguar listas]", 1: "incanexosol", 2: this.sol_id, 3: file.name }).then((res) => {
        const formData = new FormData();
        const url =
          Config.baseApiUrlUpload +
          `/upload?destino=${this.$store.state.contexto.BD[0].bco_pasta.trim()}/alunos/${this.aluno.cga
            .replace(".", "")
            .replace(".", "")
            .replace("-", "")
            .trim() + "/solicitacoes/"}&codigo=${this.sol_id}`;

        formData.append("file", file);

        axios.post(url, formData).then((res) => {
          this.$toast.success("uploaded " + file.name);
        });
      });
    },
    cancelar() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style></style>
