<template>
  <div>
    <v-overlay :value="over">
      <v-progress-circular indeterminate size="64"
        ><span style="font-size: 12px">Aguarde</span></v-progress-circular
      >
    </v-overlay>
    <iframe
      class="iframe"
      @load="evt_overlay"
      type="application/pdf"
      frameborder="1"
      :src="report"
      width="100%"
      :height="!fullscreen ? hight : '590px'"
      style="border: 1px solid #ccc"
      v-if="!viewimg"
    />

    <div
      style="width: 100%; height: 590px; overflow-y: scroll"
      v-if="viewimg"
      width="100%"
      height="300px"
    >
      {{ report }}
      <v-img :lazy-src="report" aspect-ratio="1" :src="report"></v-img>
    </div>
  </div>
</template>

<script>
export default {
  name: "viewpdf",
  props: {
    report: {
      type: String,
    },
    fullscreen: {
      type: String,
    },
    hight: {
      default: "525px",
    },
  },
  data() {
    return {
      over: false,
      viewimg: false,
    };
  },
  methods: {
    evt_overlay() {
      this.$emit("evt_overlay");
      this.over = false;
    },
  },
  created() {
    this.over = true;
    if (
      this.report.includes(".jpg") ||
      this.report.includes(".png") ||
      this.report.includes(".jpeg")
    ) {
      console.log("imagem");
      this.over = false;
      this.viewimg = true;
      this.$emit("evt_overlay");
    }
  },
};
</script>

<style>
.iframe {
  position: "absolute";
  width: window.innerWidth;
  height: window.innerHeight;
}
</style>
