import Vue from "vue";
import VueRouter from "vue-router";
import authRoutes from "@/views/modulos/auth/router";
import authAcademico from "@/views/modulos/academico/router";
import authFinanceiro from "@/views/modulos/financeiro/router";
import rh from "@/views/modulos/rh/router";
import suporte from "@/views/modulos/suporte/router";
import loja from "@/views/modulos/loja/router";
import biblioteca from "@/views/modulos/biblioteca/router";
import portalaluno from "@/views/modulos/portais/aluno/router";
import ibam from "@/views/modulos/ibam/router";
import relatorios from '@/views/modulos/relatorios/router'
import crm from '@/views/modulos/crm/router'
import igreja from '@/views/modulos/igreja/router'
import almox from '@/views/modulos/almox/router'
import patrimonio from '@/views/modulos/patrimonio/router'
import nucleosdepratica from '@/views/modulos/nucleosdepratica/router'

import gestao from '@/views/modulos/gestao/router'

import erro404 from "@/views/erro404.vue";

const dashboard  = () => import('@/views/dashboard.vue')

Vue.config.productionTip = false;

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: "",
  routes: [
    ...authRoutes,
    ...authAcademico,
    ...authFinanceiro,    
    ...rh, 
    ...suporte,
    ...loja,
    ...biblioteca,
    ...portalaluno,
    ...relatorios,
    ...crm,
    ...ibam,
    ...igreja,
    ...gestao,
    ...almox,
    ...patrimonio,
    ...nucleosdepratica,
    { path: "inscricao", redirect: "/inscricao" },    
    { path: "inscrevento", redirect: "/inscrevento"},
    { path: "matricula", redirect: "/matricula" },    
    { path: "", redirect: "/login" },
    { path: "erro404", component: erro404 },
    { path: "dashboard", component: dashboard , name: 'dashboard'},
    {
      path: "*Erro",
      redirect: "/erro404",
      component: erro404,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (
    to.path === "/login" ||
    to.path === "/selbanco" ||
    to.path === "/dashboard"
  ) {
    return next();
  }
  next();
});

export default router;
