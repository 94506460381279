<template>
  <div style="padding: 10px">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"><span style="font-size: 12px">Aguarde</span></v-progress-circular>
    </v-overlay>

    <v-row dense>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="2">
            <v-select outlined dense hide-details label="Unidade" :items="escolas" v-model="e_id" item-text="e_nomefantasia" item-value="e_id" />
          </v-col>
          <v-col cols="3">
            <v-select outlined dense hide-details label="Curso" :items="cursos" v-model="s_id" item-text="s_descricao" item-value="s_id" />
          </v-col>
          <v-col cols="3">
            <v-select outlined dense hide-details label="Serviço" :items="servicos" v-model="serv_id" item-text="serv_descricao" item-value="serv_id" />
          </v-col>
          <v-col cols="2">
            <v-select outlined dense hide-details label="Usuário destino" :items="destinos" v-model="usr_id" item-text="usr_nome" item-value="usr_id" />
          </v-col>
          <v-col cols="2">
            <v-select outlined dense hide-details label="Situação" :items="status" v-model="stat" item-text="label" item-value="data" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="2">
            <v-text-field
              v-mask="'##/##/####'"
              outlined
              dense
              hide-details
              label="Data inicial"
              v-model="dataini"
              append-icon="mdi-calendar-today"
              @click:append="showdataini = true"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-mask="'##/##/####'"
              outlined
              dense
              hide-details
              label="Data Final"
              v-model="datafim"
              append-icon="mdi-calendar-today"
              @click:append="showdatafim = true"
            />
          </v-col>
          <v-col cols="2">
            <Button label="Pesquisar" @click="pesquisar" fullscreen="true" />
          </v-col>

          <date-picker @close="showdataini = false" @input="dataini = formatData(dataselini)" v-if="showdataini" v-model="dataselini"></date-picker>

          <date-picker @close="showdatafim = false" @input="datafim = formatData(dataselfim)" v-if="showdatafim" v-model="dataselfim"></date-picker>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense style="padding: 10px">
      <v-col>
        <respondersolicitacao-listasol :registros="registros" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import Config, { listaFiliais, listaCursos, formatData, hoje } from "@/config.ts";
import sysbar from "@/components/sysbar";
import confirme from "@/components/confirme";
import DatePicker from "vue-md-date-picker";
import respondersolicitacaoListasol from "@/components/respondersolicitacao-listasol";

export default {
  name: "respondersolicitacao",
  components: { DatePicker, respondersolicitacaoListasol },
  props: ["relatorio", "showpdf"],
  data() {
    return {
      report: "",
      consreport: false,
      overlay: false,
      dataini: hoje(),
      datafim: hoje(),
      showdataini: false,
      showdatafim: false,
      seldataini: "",
      seldatafim: "",
      registros: "",
      formatData,

      escolas: "",
      cursos: "",
      servicos: "",
      tarefas: "",
      destinos: "",
      usr_id: "",
      e_id: "",
      s_id: "",
      serv_id: "",
      sxt_id: "",
      sittarefa: "Todos",
      status: [
        { label: "Aguardando Pagamento", data: "agua%pag%" },
        { label: "Aguardando Parecer", data: "agua%par%" },
        { label: "Todos", data: "%" },
        { label: "Liberados", data: "%lib%" },
        { label: "Processando", data: "process%" },
        { label: "Cancelados", data: "%canc%" },
        { label: "Indeferidos", data: "%inde%" },
        { label: "Processando", data: "%proc%" },
      ],
      stat: "agua%par%",
      aluno: "Todos",
    };
  },
  created() {
    listaFiliais().then((res) => {
      this.escolas = res.dados.data;
      this.e_id = this.escolas[0].e_id;
      this.listacursos();
    });
  },
  methods: {
    pesquisar() {
      this.overlay = true;
      http
        .send("", {
          proc: `[FX JAGUAR SOLICITACOES - RESPONDER]
${this.e_id},
${this.s_id},
${this.serv_id},
${this.usr_id},
'${this.stat.split("%").join("*")}',
'${this.dataini}',
'${this.datafim}'`,
        })
        .then((res) => {
          console.log(res.dados);
          this.overlay = false;
          if (res.dados.data[0].sol_id) {
            this.registros = res.dados.data;
          } else {
            this.registros = "";
          }
        });
    },

    listacursos() {
      listaCursos(this.e_id).then((res) => {
        this.cursos = res.dados.data;
        this.cursos.unshift({ s_id: "-1", s_descricao: "Todos" });
        this.s_id = "-1";
        this.listadestinos().then(() => {
          this.listaservicos();
        });
      });
    },
    async listaservicos() {
      await http.send("", { proc: `[fx jaguar listas] 'listaservicos'` }).then((res) => {
        this.servicos = res.dados.data;
        this.servicos.unshift({ serv_id: "0", serv_descricao: "Todos" });
        this.serv_id = "0";
        //this.listatarefas(this.serv_id);
      });
    },
    async listatarefas(serv_id) {
      await http.send("", { proc: `[fx jaguar listas] 'listatarefas',${serv_id}` }).then((res) => {
        this.tarefas = res.dados.data;
        this.tarefas.unshift({ sxt_id: "0", sxt_descricao: "Todos" });
        this.sxt_id = "0";
      });
    },
    async listadestinos() {
      await http.send("", { proc: `[fx jaguar listas] 'lista-destinatarios'` }).then((res) => {
        this.destinos = res.dados.data;
        this.destinos.unshift({ usr_id: "0", usr_nome: "Todos" });
        try {
          this.usr_id = this.destinos.filter((u) => u.usr_id === this.$store.state.contexto.BD[0].usr_id)[0].usr_id;
        } catch (e) {
          this.usr_id = "0";
        }
      });
    },
  },
};
</script>

<style></style>
